"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));

var merge = function merge(target, source) {
  Object.keys(source).forEach(function (key) {
    if (source[key] instanceof Object) target[key] && Object.assign(source[key], merge(target[key], source[key]));
  });
  return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, target), source);
};

var _default = merge;
exports.default = _default;