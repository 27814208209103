"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _stateLocal = require("state-local");

var _config = _interopRequireDefault(require("../config"));

var _utils = require("../utils");

var _createState = (0, _stateLocal.create)({
  config: _config.default,
  isInitialized: false,
  configScriptSrc: null,
  resolve: null,
  reject: null
}),
    _createState2 = (0, _slicedToArray2.default)(_createState, 2),
    getState = _createState2[0],
    setState = _createState2[1];

var MONACO_INIT = 'monaco_init';

function config(_ref) {
  var src = _ref.src,
      config = (0, _objectWithoutProperties2.default)(_ref, ["src"]);
  setState(function (state) {
    return {
      configScriptSrc: src,
      config: (0, _utils.deepMerge)(state.config, validateConfig(config))
    };
  });
}

function init() {
  var state = getState(function (_ref2) {
    var isInitialized = _ref2.isInitialized;
    return {
      isInitialized: isInitialized
    };
  });

  if (!state.isInitialized) {
    if (window.monaco && window.monaco.editor) {
      return Promise.resolve(window.monaco);
    }

    document.addEventListener(MONACO_INIT, handleConfigScriptLoad);
    (0, _utils.compose)(injectScripts, createMonacoLoaderScript, createConfigScript)();
    setState({
      isInitialized: true
    });
  }

  return (0, _utils.makeCancelable)(wrapperPromise);
}

function validateConfig(config) {
  if (config.urls) {
    informAboutDepreciation();
    return {
      paths: {
        vs: config.urls.monacoBase
      }
    };
  }

  return config;
}

function injectScripts(script) {
  return document.body.appendChild(script);
}

function createScript(src) {
  var script = document.createElement('script');
  return src && (script.src = src), script;
}

function handleConfigScriptLoad() {
  var state = getState(function (_ref3) {
    var resolve = _ref3.resolve;
    return {
      resolve: resolve
    };
  });
  document.removeEventListener(MONACO_INIT, handleConfigScriptLoad);
  state.resolve(window.monaco);
}

function createMonacoLoaderScript(configScript) {
  var state = getState(function (_ref4) {
    var config = _ref4.config,
        reject = _ref4.reject;
    return {
      config: config,
      reject: reject
    };
  });
  var loaderScript = createScript("".concat(state.config.paths.vs, "/loader.js"));

  loaderScript.onload = function () {
    return injectScripts(configScript);
  };

  loaderScript.onerror = state.reject;
  return loaderScript;
}

function createConfigScript() {
  var state = getState(function (_ref5) {
    var configScriptSrc = _ref5.configScriptSrc,
        config = _ref5.config,
        reject = _ref5.reject;
    return {
      configScriptSrc: configScriptSrc,
      config: config,
      reject: reject
    };
  });
  var configScript = createScript();

  if (state.configScriptSrc) {
    // it will be helpfull in case of CSP, which doesn't allow
    // inline script execution
    configScript.src = state.configScriptSrc;
  } else {
    configScript.innerHTML = "\n      require.config(".concat(JSON.stringify(state.config), ");\n      require(['vs/editor/editor.main'], function() {\n        document.dispatchEvent(new Event('monaco_init'));\n      });\n    ");
  }

  configScript.onerror = state.reject;
  return configScript;
}

function informAboutDepreciation() {
  console.warn("Deprecation warning!\n    You are using deprecated way of configuration.\n\n    Instead of using\n      monaco.config({ urls: { monacoBase: '...' } })\n    use\n      monaco.config({ paths: { vs: '...' } })\n\n    For more please check the link https://github.com/suren-atoyan/monaco-react#config\n  ");
}

var wrapperPromise = new Promise(function (resolve, reject) {
  return setState({
    resolve: resolve,
    reject: reject
  });
});
var _default = {
  config: config,
  init: init
};
exports.default = _default;