"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var noop = function noop() {};

var _default = noop;
exports.default = _default;